import React from "react"
import { ImWordpress } from "react-icons/im"
import { FaReact } from "react-icons/fa"
import { SiGatsby } from "react-icons/si"
import { MdOutlinePhonelink } from "react-icons/md"
import { CgIfDesign } from "react-icons/cg"
import { FaShopify } from "react-icons/fa"

const webdevList = [
  {
    icon: <MdOutlinePhonelink />,
    title: "モバイル対応",
    description: "スマートフォンサイズでも見やすいウェブサイト作成",
  },
  {
    icon: <CgIfDesign />,
    title: "デザイン",
    description: "あなたのお店のブランドイメージに沿ったカスタムデザイン",
  },
  {
    icon: <FaShopify />,
    title: "Shopifyテーマ",
    description:
      "Shopifyのテーマ構築・Liquidプログラム \n 初期テーマだけではできないカスタマイズなどを行います ",
  },
  {
    icon: <ImWordpress />,
    title: "Wordpress",
    description:
      "世界で最も多く使われているコンテンツマネジメントシステムWordpressによる構築",
  },
  {
    icon: <FaReact />,
    title: "JAMSTACK",
    description:
      "パフォーマンスとセキュリティの高いJAMSTACKによるヘッドレスCMS構築。\n*このページはReactとGatsbyを使って作られています。",
  },
  {
    icon: <SiGatsby />,
    title: "Gatsby",
    description:
      "React+ Gatsbyによる高速ウェブサイト構築。JAMSTACKでセキュリティも高い",
    // url: "https://gatsbyjs.com",
  },
]

export default webdevList
