import React from "react"
import { Parallax } from "react-parallax"
import bgImage from "../../assets/images/webdev.jpg"
import { Link } from "gatsby"
import Services from "../../components/Services"
import webdevList from "../../constants/webdev-list"
import Seo from "../../components/utilities/Seo"
import Portfolio from "../../components/Portfolio"
const BuildingWebsite = () => {
  return (
    <>
      <section>
        <Seo title="ウェブサイト構築" />
        <Parallax
          bgImage={bgImage}
          bgImageAlt="shopify"
          strength={400}
          blur={{ min: 0, max: 1 }}
        >
          <div className="parallax-box">
            <div className="parallax-info">
              <h1>ウェブサイト構築</h1>
              <h2>あなたのビジネスのホームページ作りませか？</h2>
              <p>
                見やすく・快適なホームページ制作・運営のお手伝いをいたします。
              </p>
              <div>
                <Link to="/contact" className="btn-primary">
                  お問合せ
                </Link>
              </div>
            </div>
          </div>
        </Parallax>
        <section>
          <Services servicesList={webdevList} />
        </section>
        <section>
          <Portfolio />
        </section>
      </section>
    </>
  )
}

export default BuildingWebsite
