import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const Portfolio = () => {
  const data = useStaticQuery(graphql`
    {
      allMyportfolioJson {
        nodes {
          name
          platform
          url
          image {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
          description
        }
      }
    }
  `)

  const {
    allMyportfolioJson: { nodes: portfolio },
  } = data

  return (
    <div className="portfolio">
      {portfolio.map((item, index) => {
        return (
          <div key={index} className="portfolio-card">
            <span>{item.platform}</span>
            <a href={item.url} target="_blank" rel="noreferrer">
              <GatsbyImage image={getImage(item.image)} alt={item.name} />
            </a>
            <h3>{item.name}</h3>
            <p>{item.description}</p>
          </div>
        )
      })}
    </div>
  )
}

export default Portfolio
