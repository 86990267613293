import { Link } from "gatsby"
import React from "react"
const Services = ({ servicesList }) => {
  console.log(servicesList)
  return (
    <>
      <section>
        <div className="services-grid">
          {servicesList.map((service, index) => {
            return (
              <div key={index} className="services-card">
                {service.icon}
                <h2>{service.title}</h2>
                <p>{service?.description}</p>
                {service.url ? (
                  <div>
                    <Link to={service?.url} className="btn-invert">
                      詳しく
                    </Link>
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Services
